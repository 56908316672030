import { createStore } from 'vuex'
import menus from "./modules/menus";

const store = createStore({
	state: {
		token: '',
		isHomeMap: false,      //是否显示首页地图
		isShowRotateDialog: false,      //是否显示首页地图
		posX: 0,
		posY: 0,
		bdMapZoom: 1,    // 设定地图级别 1为省，2为市，3为县
		bdMapType: 'B_EARTH_MAP',    // 地图类型 卫星地球：B_EARTH_MAP，普通地球：B_NORMAL_MAP
		projectType: 1,    // 项目类型 项目一：1，项目二：2， 项目三：3
	},
	mutations: {
		SET_TOKEN: (state, token) => {
			state.token = token
			localStorage.setItem("token", token)
		},
		SET_MAP_STATE: (state, value) => {
			state.isHomeMap = value
		},
		SET_ROTATE_DIALOG_STATE: (state, value) => {
			state.isShowRotateDialog = value
		},
		SET_POSITION_X: (state, value) => {
			state.posX = value
		},
		SET_POSITION_Y: (state, value) => {
			state.posY = value
		},
		SET_MAP_ZOOM: (state, value) => {
			state.bdMapZoom = value
		},
		SET_MAP_TYPE: (state, value) => {
			state.bdMapType = value
		},
		SET_PROJECT_TYPE: (state, value) => {
			state.projectType = value
		}
	},
	// getters:{
	// 	getMapState (state){
	// 		return state.isHomeMap
	// 	}
	// },
	actions: {},
	modules: {
		menus
	}
})

export default store