import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/lib-flexible/flexible'
import axios from 'axios'
import VueAxios from 'vue-axios'
import * as echarts from 'echarts'
import 'echarts-gl' // 3d图表库
import HighchartsVue from 'highcharts-vue'
import highcharts from 'highcharts'
import highcharts3d from 'highcharts/highcharts-3d'
import Video from 'video.js'
import 'video.js/dist/video-js.css'



Date.prototype.format = function (fmt) {
    const o = {
        "M+": this.getMonth() + 1,                 //月份
        "d+": this.getDate(),                    //日
        "h+": this.getHours(),                   //小时
        "m+": this.getMinutes(),                 //分
        "s+": this.getSeconds(),                 //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds()             //毫秒
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    }
    for (const k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
    }
    return fmt;
}
const app = createApp(App)
app.config.globalProperties.$echarts = echarts;

app.config.globalProperties.$store = store

app.config.globalProperties.$video = Video

app.config.productionTip = false

// Vue.prototype.$baseUrl = "http://192.168.0.14:8081"
// app.config.globalProperties.$baseUrl = "http://27.150.18.248:9087"
app.config.globalProperties.$baseUrl = "http://192.168.2.8:8088"
// app.config.globalProperties.$baseUrl = "http://192.168.2.10:5234"
// Vue.prototype.$baseUrl = "http://27.150.18.248:8099"

app.config.globalProperties.$baseUrlTwo = 'http://27.150.18.248:9085'  //主要用于获取图片getUrl方法

app.use(store).use(router).use(ElementPlus).use(VueAxios, axios).use(HighchartsVue)
highcharts3d(highcharts)





app.mount('#app')

// 全局取消浏览器默认鼠标拖动选中文字的方法
document.body.onselectstart = document.body.ondrag = function(){
    return false;
}