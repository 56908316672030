import {request} from "./network";

// 登录获取token
export function login(params) {
    return request({
        method: "post",
        url: '/api/login',
        data:params
    })
}

// 获取各地区项目总数
export function getAreaAndCount(params) {
    return request({
        method: "get",
        url: '/api/index/area/count',
        params:params
    })
}
// 获取各地区项目状态占比堆积图数据
export function getWorkCount(params) {
    return request({
        method: "get",
        url: '/api/index/work/count',
        params:params
    })
}
// 获取投资金额模块的各地区投资金额数据
export function getAmountInvested(params) {
    return request({
        method: "get",
        url: '/api/index/area/total/amount',
        params:params
    })
}
// 获取投资金额模块的各地区各地区投资情况柱状图数据
export function getAreaAmountInvested(params) {
    return request({
        method: "get",
        url: '/api/index/area/amount',
        params:params
    })
}
// 获取大屏监控播放地址列表（带封面图）
export function getEquipmentPic(params) {
    return request({
        method: "get",
        url: '/api/index/equipment',
        params:params
    })
}
// 根据监控index_code获取大屏监控播放视频流
export function getEquipmentPreview(params) {
    return request({
        method: "get",
        url: '/api/equipments/preview',
        params:params
    })
}
// 获取各地区项目总数、开工率、完工率、投资总额、年度总额
export function getProjectInfo(params) {
    return request({
        method: "get",
        url: '/api/index/area/project/info',
        params:params
    })
}
// 获取项目等级占比数据
export function getProjectType(params) {
    return request({
        method: "get",
        url: '/api/index/area/project/type',
        params:params
    })
}
