import axios from 'axios'

// ES6 Promise的封装
export function request(options) {
    return new Promise((resolve, reject) => {
        // 1.创建axios的实例对象
        const instance = axios.create({
            headers: {'Authorization': 'Bearer ' + window.localStorage.getItem("token")},
             baseURL: 'http://sup.hhcycj.com',
        })
        //requset拦截器
        instance.interceptors.request.use(
            
            config => {
                {
                    console.log(window.localStorage.getItem("token"),'token')
                    config.headers['Authorization'] = 'Bearer ' + window.localStorage.getItem("token") // 让每个请求携带自定义token
                }       
                return config
            },
            error => {
                console.log(error)
                Promise.reject(error)
            }
        )
        // 过滤器(拦截器)
        instance.interceptors.response.use(res => {
            return res.data
        })

        // 通过实例发送网络请求
        instance(options)
            .then(res => {
                resolve(res)
            }).catch(err => {
            reject(err)
        })
    })
}
