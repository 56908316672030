<template>
  <router-view v-if="isRouterAlive" @mouseleave="closeAreaDialog"/>
</template>
<script>
export default {
  name:'App',
  provide() {
    return{
      reload:this.reload
    }
  },
  data(){
    return{
      isRouterAlive:true
    }
  },
  methods:{
    reload(){
      this.isRouterAlive = false;
      this.$nextTick(function(){
        this.isRouterAlive = true;
      })
    },
    closeAreaDialog(){
      this.$store.commit("SET_ROTATE_DIALOG_STATE", false)
    }
  }
}
</script>

<style>
* {
  padding: 0;
  margin: 0;
}
html, #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* background: #ebeef5; */
  background: black;
}
@font-face {
  font-family: SourceHanSansCNRegular;
  src: url('./assets/font/SourceHanSansCNRegular.ttf');
}
@font-face {
  font-family: DINPro-CondensedBold;
  src: url('./assets/font/DIN Condensed Bold.woff.ttf');
}
@font-face {
  font-family: DINPro;
  src: url('./assets/font/DINPro-Bold.otf');
}
@font-face {
  font-family: DS-Digital;
  src: url(./assets/font/DS-DIGI-1.ttf), url(./assets/font/DS-DIGIB.TTF), url(./assets/font/DS-DIGII-3.ttf), url(./assets/font/DS-DIGIT-4.ttf);
}
@font-face {
  font-family: PangMenZhengDao;
  src: url(./assets/font/PangMenZhengDaoBiaoTiTi-1.ttf);
}
@font-face {
  font-family: PingFang SC;
  src: url(./assets/font/PingFangLight.ttf);
}
@font-face {
  font-family: PingFangSC-Light;
  src: url(./assets/font/PingFangSCRegular.ttf);
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
