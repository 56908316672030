import { createRouter, createWebHistory, RouterLink } from 'vue-router'
import store from "../store"
import axios from 'axios'
import {getMenu} from "../api/request.js"

const Home = () => import('../views/Home/Home.vue')
const Login = () => import('../views/Login/Login.vue')

const routes = [
  {
    path: '/',
		redirectTo: '/login',
		component: Login
  },
  {
		path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
]

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {

	let hasRoute = store.state.menus.hasRoutes

  localStorage.setItem('token','eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImlhdCI6MTY1MDk2MTc3NSwiZXhwIjoxNjUxNTY2NTc1fQ.8Idrr08zOQI0uiNJbY1vP0of57BhnXbryLKbfKgf21H2RrZZ9Ts7gmORhDqQ932OedPbqRoQA_OaQNq4aA9YcQ')

	let token = localStorage.getItem("token")

	if (to.path == '/login') {
		next()

	} else if (!token) {
		next({path: '/login'})


	} else if(token && !hasRoute) {
		// getMenu().then(res => {

		
    //   console.log(res,'123123')
    //   // console.log(res.data.data)
		// 	// 拿到menuList
		// 	store.commit("setMenuList", res.data.nav)

		// 	// 拿到用户权限
		// 	// store.commit("setPermList", res.data.data.authoritys)
    //   // console.log(store.state.menus.menuList,"权限")

		// 	// console.log("动态路由", res.data.data)

		// 	// 动态绑定路由
		// 	let newRoutes = router.options.routes

    //   console.log(newRoutes,'newRoutes');

		// 	res.data.nav.forEach(menu => {
		// 		if (menu.children) {
		// 			menu.children.forEach(e => {

		// 				// 转成路由
		// 				let route = menuToRoute(e)

		// 				// 吧路由添加到路由管理中
		// 				if (route) {
		// 					newRoutes[2].children.push(route)
    //           router.addRoute(newRoutes[2])
		// 				}

		// 			})
		// 		}
		// 	})

    //   console.log(router,'router');
    //   // console.log(newRoutes,'newRoutes');
		// 	router.addRoute(newRoutes)

		// 	hasRoute = true
		// 	store.commit("changeRouteStatus", hasRoute)
		// })
	}



	next()
})


// // 导航转成路由
// const menuToRoute = (menu) => {

// 	if (!menu.component) {
// 		return null
// 	}

// 	let route = {
// 		name: menu.name,
// 		path: menu.path,
// 		meta: {
// 			icon: menu.icon,
// 			title: menu.title
// 		}
// 	}
// 	route.component = () => import('@/views/homeMenu/' + menu.component +'.vue')

// 	return route
// }


export default router
